<!--支付成功页
 * @Author: your name
 * @Date: 2021-03-04 09:14:59
 * @LastEditTime: 2021-03-04 14:33:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\divs\appointment\detail.vue
-->
<template>
  <div class="page-wrap">
    <div class="appointmentDetail">
      <div class="appointment-head" :class="appointmentStatus ? '' : 'fail'">
        <template v-if="appointmentStatus">
          <div class="subject">
            <img class="img" :src="require('../../assets/img/sucess_icon.png')">
            挂号序号：{{ detail.yyxh }}
          </div>
          <div v-if="appointmentStatus">
            {{ detail.ddztmc }}
          </div>
          <div v-else>
            已预约待支付
          </div>
        </template>
        <template v-else>
          <div class="subject">
            <img class="img" :src="require('../../assets/img/fail_icon.png')">
            已退号
          </div>
        </template>
      </div>
      <div class="appointmentDetail-body">
        <div class="info">
          <div class="info-item">
            <span class="info-item-title">就诊人</span>
            <div class="con">
              <span class="name">{{ detail.jzrxm }}</span>
              {{ detail.jzrkh }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊类型</span>
            <div class="con">
              {{ detail.hylxmc }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊科室</span>
            <div class="con">
              {{ detail.ksmc }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊日期</span>
            <div class="con">
              {{ detail.hyrq }} {{ detail.sxwbzmc }}
            </div>
          </div>
          <div class="info-item info-item__tip">
            <span class="info-item-title">挂号费用</span>
            <div class="con">
              ¥ {{ detail.ghf }}
            </div>
            <div class="payment-tips">
              <span>当前平台仅支持自费就诊，医保暂未开通，无法支付</span>
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">确诊疾病</span>
            <div class="cons">
              {{ ill }}
            </div>
          </div>
          <div class="info-item info-item-s">
            <div class="label">
              病情主诉
            </div>
            <div class="ill-desc">
              {{ detail.bqzs }}
            </div>
          </div>
          <div class="info-item info-item-s">
            <div class="label">
              上传材料
            </div>
            <van-grid :border="false" :column-num="3">
              <van-grid-item v-for="(image,index1) in detail.pictures" :key="index1">
                <img class="upImage" :src="image">
              </van-grid-item>
            </van-grid>
          </div>
          <div class="info-item">
            <span class="info-item-title">凭证单号</span>
            <div class="con">
              {{ detail.yydbm }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">提交日期</span>
            <div class="con">
              {{ detail.tjrq }}
            </div>
          </div>
        </div>
      </div>
      <van-dialog
        v-model="isCancelAppointVisible"
        :title="'提示'"
        show-confirm-button
        show-cancel-button
        theme="round-button"
        confirm-button-text="确定"
        cancel-button-text="取消"
        confirm-button-color="#3A8AE5"
        cancel-button-color="#DDDDDD"
        @confirm="sure"
        @cancel="cloose"
      >
        <div class="reason-list">
          请确定是否要取消挂号！
        </div>
      </van-dialog>
      <van-dialog
        v-model="isSecendAppointVisible"
        :title="'退号申请已提交！'"
        show-confirm-button
        show-cancel-button
        theme="round-button"
        confirm-button-text="确定"
        cancel-button-text="取消"
        confirm-button-color="#3A8AE5"
        cancel-button-color="#DDDDDD"
        @confirm="confirm"
      >
        <div class="reason-list">
          系统在3～15个工作日内将挂号费用原路返回至您的支付宝账号中,请您耐心等待!
        </div>
      </van-dialog>
    </div>
    <div v-if="appointmentStatus" class="modal-foot">
      <!--      <div class="btn btn-default" @click="cancel">-->
      <!--        取消挂号-->
      <!--      </div>-->
      <div class="btn btn-primary" @click="goback">
        返回首页
      </div>
    </div>
    <div v-else class="modal-foot">
      <div class="btn btn-primary" @click="goback">
        返回首页
      </div>
    </div>
  </div>
</template>
<script>
import {appointModel} from '@/api/appoint'
import {CONSTANT} from '../../utils/constant'
export default {
  data(){
    return{
      detail:{},
      ill:'',
      appointmentStatus: true, // 预约状态： false->已取消
      isCancelAppointVisible: false, // 是否显示取消原因
       isSecendAppointVisible: false,
      show:false,
      payStaus:false,
      tabIndex: null,
      orderid:''
    }
  },
  mounted(){
    //let type = this.$route.query.type
    const {thbz,id}=this.$route.query
    this.orderid=id
    if(thbz==='2'){//订单状态2为退号
      this.appointmentStatus=false
    }
  this.getRegistrationDetails()
    // if(type=='2'){
    //   this.registerDetail()
    // }else{
    //   this.getDetail()
    // }

  },
  methods:{
        goback(){
      this.$router.push({
        path:'/home'
      })
    },
    cancel(){
      if (this.detail.hzzt == '0') {
              this.isCancelAppointVisible = true
      } else {
         // console.dir(this.detail,'121')
         this.$toast({
           message:'未就诊情况下才能退号！'
         })

        }
    },
    sure(){
      this.isCancelAppointVisible = false
      this.isSecendAppointVisible = true
    },
    cloose(){
       this.isCancelAppointVisible = false
    },
    confirm(){
        appointModel.cancelRegistration({registerId:this.orderid}).then(res=>{
             if(res.code==CONSTANT.SUCCESS_CODE){
                  this.isSecendAppointVisible = false
                  this.appointmentStatus = false
             }
        }).catch(error=>{
          this.$toast({
            message:error.msg
          })
        })
    },
    getRegistrationDetails(){
      // console.dir('123123')
         appointModel.queryRegisterDetail(this.orderid).then(res=>{
           if(res.code==CONSTANT.SUCCESS_CODE){
              this.detail = res.data
          if(res.data.diagnosis){
          this.detail.diagnosis.map((v)=>{
            this.ill+=v.zdmc+';'
          })
        }
           }
         })
    },
    //挂号详情
    registerDetail(){
      let registerId = this.$route.query.id
      appointModel.queryRegisterDetail(registerId).then(res=>{
        this.detail = res.data
        if(res.data.diagnosis){
          this.detail.diagnosis.map((v)=>{
            this.ill+=v.zdmc+';'
          })
        }
      })
    },
    //预约详情
    getDetail(){
      let id = this.$route.query.id
      appointModel.appointmentDetail({appointId:id}).then(res=>{
        this.detail = res.data
        if(res.data.diagnosis){
          this.detail.diagnosis.map((v)=>{
            this.ill+=v.zdmc+';'
          })
        }
        if(res.data.hyrq){
          this.payStaus=true
        }
      })
    },
    cancelChoose(e){
      let index = e.target.dataset.current || e.currentTarget.dataset.current
      this.tabIndex=index
    },
    cancelsChoose(){
      this.show = true
    },
    cancelSure(){

    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.payment-tips{
  text-align: right;
  width: 686px;
  box-sizing: border-box;
  margin: 0 auto 0;
  border-radius: 12px;
  color: #f56c6c;
  font-size: 22px;
}
div {
  box-sizing: border-box;
}
.page-wrap{
  padding-bottom: 108px;
  background: @color-F2F2F2;
}
.appointmentDetail {
  padding: 0 32px;
  overflow: scroll;
  .appointment-head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 272px;
    padding: 40px;
    line-height: 33px;
    font-size: 26px;
    color: #fff;
    text-align: center;
    background: #1c9cf6;

    &.fail {
      background: #b5b5b5;
    }

    .subject {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 33px;
      font-size: 32px;
      line-height: 45px;

      .img {
        width: 44px;
        height: 44px;
        margin-right: 16px;
      }
    }
  }
  .appointmentDetail-body {
    position: relative;
    z-index: 2;
    padding: 223px 0px 0px;

    .info {
      padding: 40px 32px;
      border-radius: 20px;
      background: #fff;

      .info-number {
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 28px;
        color: #3d3d3d;
        text-align: center;
      }

      .info-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 0;
        line-height: 40px;
        font-size: 28px;
        color: #3d3d3d;
        &.info-item__tip{
          padding-bottom: 0;
        }

        &.info-item-s {
          display: block;

          .label {
            margin-bottom: 24px;
            font-weight: bold;
          }
          .upImage {
            width: 120px;
            height: 120px;
          }
        }

        .info-item-title {
          font-weight: bold;
        }

        .con {
          flex: 1;
          color: #666;
          text-align: right;

          .name {
            margin-right: 24px;
          }

          .price {
            color: #dc3030;
          }
        }

        .cons {
          flex: 1;
          color: #666;
          text-align: right;
          width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ill-desc {
          line-height: 33px;
          font-size: 26px;
          color: #999;
        }
      }

      .info-item-upload {
        align-items: flex-start;

        .con {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .pic,
        .more {
          width: 120px;
          height: 120px;
          border-radius: 8px;
        }

        .pic {
          margin-right: 55px;
          background: #ccc;
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eee;

          .img {
            width: 34px;
            height: 6px;
          }
        }
      }
    }
  }


  .reason-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding:0px 32px;
    margin: 40px 0 ;
    .reason-item {
      min-width: 160px;
      height: 52px;
      margin: 12px 22px;
      line-height: 52px;
      font-size: 28px;
      color: #666;
      text-align: center;
      background: #f2f2f2;

      &.active {
        color: #fff;
        background: #1c9cf6;
      }
    }
  }
  .van-dialog {
      width:686px;
  }
  /deep/.van-dialog--round-button .van-dialog__confirm {

  }
  /deep/.van-dialog--round-button .van-dialog__cancel {

  }
  /deep/.van-goods-action-button--first{
     border-radius: 12px;
  }
  /deep/.van-goods-action-button--last{
    border-radius: 12px;
  }
}
.modal-foot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 98px;
  line-height: 98px;
  background: #F2F2F2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  .btn {
    width: 686px;
    height: 68px;
    line-height: 68px;
    font-size: 30px;
    text-align: center;
    border-radius: 12px;
  }
  .btn-default {
    color: #666;
    background: #ddd;
  }
  .btn-primary {
    color: #fff;
    background: #1c9cf6;
  }
}
</style>
